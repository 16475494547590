@import 'oberland17';
.ChooseProducersFromMap {
  .info-window {
    padding: 10px;

    main {
      display: flex;
      flex-flow: row;

      .image {
        width: 100px;
        height: 100px;
        margin-right: 15px;
        margin-bottom: 15px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          box-shadow: 1px 1px 4px black;
        }
      }

      .info {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        padding-right: 10px;

        h6 {
          a {
            color: $cells-text-colour;
            font-weight: inherit;
            font-size: inherit;
            line-height: inherit;

            &:hover {
              text-decoration: none;
            }
          }
        }

        ul {
          padding-left: 10px;

          li {
            list-style-type: none;
          }
        }
      }
    }

    .controls {
      width: -moz-fit-content;
      width: fit-content;
      margin: auto;

      .disabled {
        pointer-events: none;
      }
    }
  }

  @media only screen and (max-device-width: 450px) {
    .info-window {
      padding: 0;

      main {
        .image {
          display: none;
        }

        .info {
          padding-right: 0;

          ul {
            width: 100%;
            padding: 0;

            li {
              width: 100%;
              text-align: center;
            }
          }
        }
      }

      .controls {
        .btn {
          width: 100%;
          font-size: 0.8rem;
        }
      }
    }
  }
}

.map-container {
  height: 80vh;
}
