.ConsumerFlow {
  display: flex;
  flex-flow: column;
}

.ConsumerFlow h3 {
  margin-bottom: 25px;
}

.ConsumerFlow .continue-button-container {
  width: 100%;
  text-align: center;
}

.ConsumerFlow .continue-button {
  width: 39px;
  height: 43px;
}
