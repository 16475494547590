@import 'oberland17';
.PotentialCheckMargin {
  display: flex;
  flex-flow: column;
  text-align: left;

  .margin-and-recommendation {
    display: flex;
    flex-flow: row;

    .plantMargin {
      margin-right: 50px;
    }

    .recommendation {
      font-size: smaller;
    }

    @media only screen and (max-device-width: 576px) {
      flex-flow: column;

      .plantMargin {
        margin-right: initial;
      }
    }
  }

  .info {
    display: flex;
    flex-flow: column;
    margin-top: 20px;

    .estimation {
      font-size: 1.6em;
      height: 0;
      overflow: hidden;
      transition: height 500ms ease-in;
    }

    .estimation-height {
      height: 50px;
    }

    .explanation {
      font-size: 0.8em;
      max-width: 500px;
    }
  }
}
