@import 'oberland17';
.Contact {
  text-align: left;

  #message {
    height: 140px;
  }

  .submit-button-container {
    text-align: center;
  }

  .form-success {
    margin-top: 20px;
    text-align: center;

    p {
      margin: 0;
    }
  }
}

#EPilot-Iframe-5f74ddfa-aa9b-4c35-90bb-263a8852b115 {
  min-width: 440px;
  min-height: 350px;
}
