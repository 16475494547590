@import 'oberland17';
.FAQ {
  text-align: left;

  main {
    margin-top: 20px;

    h2 {
      margin-top: 30px;
    }

    .row-question {
      margin-bottom: 10px;

      .CollapsibleInformation {
        .show {
          .title {
            font-weight: 600;
          }
        }

        p {
          padding: 0 30px;
        }
      }
    }
  }
}
