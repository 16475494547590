@import 'oberland17';
.Blog {
  margin: 50px 0 100px 0;

  h2 {
    padding: 0 15px;
    color: $cells-blue;
    font-size: 56px;
    text-transform: initial;
    margin-bottom: 40px;
  }

  .blog-posts-with-controls {
    position: relative;
    display: flex;
    align-items: center;
    height: 460px;

    .scroll-control {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 1;

      display: flex;
      flex-flow: column;
      justify-content: center;
      padding: 4px;

      font-size: 30px;
      color: #fff;
      cursor: pointer;

      &.left {
        left: 0;
      }

      &.right {
        right: 0;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }

  .blog-posts {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    scroll-behavior: smooth;
  }

  .blog-post {
    display: block;
    position: relative;
    min-width: 330px;
    width: 330px;
    height: 460px;
    margin: 0 12px;
    border-radius: 5px;
    overflow: hidden;

    font-weight: initial;
    font-size: initial;

    &:hover {
      box-shadow: 2px 2px 5px;
      cursor: pointer;

      .blog-entry-image {
        transform: scale(1.03);
      }
    }

    &:nth-of-type(1) {
      margin-left: 0;
    }

    &:last-of-type() {
      margin-right: 0;
    }

    .image-zoom-wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;

      .blog-entry-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.05s ease;
      }
    }

    .post-text {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      display: flex;
      flex-flow: column;
      align-items: flex-start;
      justify-content: flex-end;

      background-color: rgba(0, 0, 0, 0.12);
      color: #fff;
      text-align: left;

      padding: 30px;

      .post-date-categories {
        font-size: smaller;
      }

      .post-title {
        line-height: 22px;
        font-size: large;
      }

      .read-post-button {
        margin-top: 12px;
        font-size: small;
      }
    }
  }
}
