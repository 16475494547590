@import 'oberland17';
.PowerPlant {
  .header-image {
    margin-bottom: 50px;
    max-width: 90%;
    width: 800px;
    height: 200px;
    box-shadow: 1px 1px 4px black;
    object-fit: cover;
  }

  .header {
    h1 {
      margin-bottom: 30px;
    }

    img {
      margin-bottom: 50px;
      max-width: 90%;
      width: 800px;
      height: 200px;
      box-shadow: 1px 1px 4px black;
      object-fit: cover;
    }

    .producer-buttons {
      display: flex;
      align-items: center;
      padding-bottom: 30px;
      font-size: 20px;

      a {
        font-size: 20px;
        margin-right: 10px;
      }

      svg {
        cursor: pointer;
        color: $cells-blue;

        &.disabled {
          margin-right: 10px;
          color: $cells-dark-grey;
          cursor: initial;
        }
      }
    }
  }

  .breadcrumbs {
    text-align: left;
    margin-bottom: 20px;
  }

  .image-thumbnail {
    width: 128px;
    height: 96px;
    margin: 10px;
    box-shadow: 1px 1px 4px black;
  }

  .description {
    text-align: left;
    white-space: pre-line;
  }

  .center-text {
    text-align: center;
  }

  .additional-information {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: left;

    h3 {
      margin-bottom: 30px;
    }

    .image-with-label {
      display: flex;
      flex-flow: column;
      align-items: center;

      @media only screen and (max-device-width: $sm-breakpoint) {
        margin-bottom: 30px;
        text-align: center;
      }

      .installed-capacity-speedometer,
      .yearly-energy-image,
      .plant-image {
        margin-bottom: 10px;
      }

      .yearly-energy-image {
        .bolt-icon {
          height: 130px;
          width: 130px;
        }

        svg * {
          fill: url(#yearly-energy-image-gradient);
        }
      }

      .plant-image {
        img {
          height: 130px;
        }
      }

      .label {
        text-align: center;
      }
    }
  }

  .location {
    margin-bottom: 50px;

    h3 {
      margin-bottom: 30px;
      text-align: left;
    }
  }

  .dmsp-logo-outer {
    text-align: left;
    margin-top: 20px;

    h3 {
      margin-bottom: 30px;
    }

    .dmsp-logo {
      width: 150px;
      height: 100px;
    }
  }
}
