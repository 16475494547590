@import 'oberland17';
.App {
  background-image: $app-background-image;
  background-size: 115%;
  text-align: center;
  font-family: $font-family-text;
  color: $cells-text-colour;
  font-size: $font-size-text;
  line-height: $line-height-text;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-header;
}

h1 {
  font-size: $h1-font-size;
  color: $cells-text-colour;
  text-transform: uppercase;
}

h2 {
  font-size: $h2-font-size;
  color: $cells-text-colour;
  text-transform: uppercase;
}

h3 {
  font-size: $h3-font-size;
  color: $cells-text-colour;
  text-transform: uppercase;
}

a {
  color: $link-color;
  font-size: $font-size-text;
  line-height: $line-height-text;
  font-family: $font-family-text;
  font-weight: bold;
}

main {
  display: block;
}

.page {
  margin-top: 40px;
  margin-bottom: 40px;
}

.site-header {
  padding: 10px 0;

  .strom-kaufen-nav-item {
    display: none;
  }
}

.footer {
  padding: 20px 0 20px 0;
  text-align: left;
  color: $cells-dark-grey;

  hr {
    margin-bottom: 4px;
  }

  .copyright-impressum {
    display: flex;
    flex-flow: row;
    justify-content: space-between;

    a {
      color: $cells-dark-grey;
      font-weight: normal;
    }
  }

  .social-media-links {
    ul {
      display: flex;
      flex-flow: row;
      align-content: center;
      justify-content: center;
      margin: 10px 0 10px 0;
      padding: 0;

      text-align: center;

      li {
        list-style: none;
        padding: 0 10px;

        a {
          display: flex;
          align-items: center;
          justify-content: center;

          width: 30px;
          height: 30px;

          border-radius: 10px;
          background-color: $cells-blue;
          color: white;

          &:hover {
            background-color: $cells-light-blue;
          }
        }

        svg {
          font-size: 18px;
        }
      }
    }
  }

  @media only screen and (max-device-width: 470px) {
    font-size: smaller;

    a {
      font-size: smaller;
    }
  }
}

.error {
  color: #dc3545;
  font-size: small;
}

.logo {
  width: $logo-width;
  height: $logo-height;
}

.navlink-onclick {
  cursor: pointer;
}

.__react_component_tooltip {
  max-width: 500px;
  text-align: left;
}

.flex-center {
  justify-content: center;
}

.smaller {
  font-size: smaller;
}

.nospinners::-webkit-inner-spin-button,
.nospinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.nospinners {
  -moz-appearance: textfield;
}

.optional {
  font-size: smaller;
}
