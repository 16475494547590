.PotentialCheck {
  display: flex;
  flex-flow: column;
}

.PotentialCheck h3 {
  margin-bottom: 25px;
}

.PotentialCheck form,
.PotentialCheck .question-group,
.PotentialCheck .has-rc,
.PotentialCheck .has-rlm-meter {
  display: flex;
  flex-flow: column;
  text-align: left;
}

.PotentialCheck input {
  width: 350px;
}

.PotentialCheck select {
  margin-bottom: 1.5rem;
  width: 350px;
  align-self: center;
}

.PotentialCheck .progress-tracker-spacing {
  height: 50px;
}

.PotentialCheck .continue-button-container {
  align-self: center;
}
