@import 'oberland17';
.MarginControl {
  display: flex;
  flex-flow: row;
  align-items: center;

  .text {
    width: 100px;
  }

  .plus-minus {
    width: 20px;
    height: 20px;
    margin: 0 2px;
    padding: 0;
    line-height: 0;
  }

  .plus-minus-image {
    cursor: pointer;
    width: 20px;
    margin: 0 2px;
  }
}
