@import 'oberland17';
$primary: $cells-blue;
$secondary: $cells-grey-elements;

// Forms

$input-border-color: $cells-grey-elements;
$input-focus-border-color: $cells-dark-grey;

$input-focus-box-shadow: none;

$input-placeholder-color: $cells-dark-grey;

@import "~bootstrap/scss/bootstrap.scss";
