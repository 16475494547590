@import 'oberland17';
.plant-margins {
  .MarginControl {
    justify-content: flex-end;
  }

  .vat-info {
    font-size: x-small;
    color: $cells-dark-grey;
    line-height: initial;
    margin-top: 8px;
  }
}
