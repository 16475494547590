.ProducerContract .contract-fields {
  text-align: left;
}

.ProducerContract h1 {
  margin-bottom: 30px;
}

.ProducerContract h3 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.ProducerContract label {
  margin: 0;
}

.ProducerContract label span {
  width: 300px;
}

.ProducerContract input {
  width: 300px;
}

.ProducerContract input.full-length,
.ProducerContract .capacity input,
.ProducerContract .plantYearlyEnergy input,
.ProducerContract .has-rlm-meter label,
.ProducerContract .has-rc label {
  width: 100%;
}

.ProducerContract input.short {
  width: 80px;
}

.ProducerContract input.same-bank-data {
  width: initial;
}

.ProducerContract .margin {
  display: flex;
  align-items: center;
}

.ProducerContract .margin input {
  width: 80px;
  margin-right: 10px;
}

.iban-bic {
  margin-bottom: 1rem;
}

.ProducerContract .bank-sepa {
  padding-left: 10px;
}

.ProducerContract .bank-sepa span {
  width: initial;
}
