$cells-blue: #001457;
$cells-dark-grey: #676767;
$cells-grey-elements: #dadada;
$cells-grey-background: #f5f5f5;
$cells-white: #ffffff;
$cells-black: #000000;
$cells-text-colour: #444444;
$cells-light-blue: #0029cc;
$cells-dark-blue: #001457;
$accent-colour: #ff1a00;

$producer-colour-1: $cells-blue;
$producer-colour-2: $accent-colour;
$producer-colour-3: #feb019;
$producer-colour-4: #00e396;
$producer-colour-5: #775dd0;

/* klaro cookie consent variables */
:root {
  --green1: #001457;
  --green2: #0029cc;
  --blue1: #0029cc;
  --light1: #444444;
  --dark2: #676767;
}

$lg-breakpoint: 1199px;
$md-breakpoint: 991px;
$sm-breakpoint: 767px;
$mobile-break-point: 925px;

$font-family-text: "Verdana", sans-serif;
$font-size-text: 14px;
$line-height-text: 28px;

$font-family-header: 'PT Sans', sans-serif;
$h1-font-size: 58px;
$h2-font-size: 24px;
$h3-font-size: 20px;

$link-color: $accent-colour;

$app-background-image: none;

$logo-width: 161px;
$logo-height: 120px;

$landing-page-header-min-height: calc(100vh - 180px);
$landing-page-information-font-size: 22px;
$landing-page-information-line-height: 32px;

a {
  text-decoration: underline;

  &.btn,
  &.nav-link {
    text-decoration: none;
    font-weight: unset;
    margin: 3px;
  }
}

h1,
h2,
h3 {
  color: $cells-blue;
}

.site-header {
  a {
    text-decoration: none;
  }

  .about-us-nav-item {
    order: 2;
  }

  .faq-nav-item {
    order: 3;
  }

  .contact-nav-item {
    order: 1;
  }

  .strom-verkaufen-nav-item {
    order: 5;
  }

  .strom-kaufen-nav-item {
    display: initial;
    order: 4;
    
    background-color: $accent-colour;

    .nav-link {
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .blog-nav-item {
    display: none;
  }
}

.footer {
  .social-media-links ul li a {
    &:hover {
      background-color: $cells-blue;
    }
  }
}

.LandingConsumer {
  h2,
  .information h2 {
    font-size: 40px;
    font-family: 'PT Sans', sans-serif;
  }

  h3,
  .information .TextWithIcon header h3 {
    font-size: $h2-font-size;
    font-weight: bold;
    text-transform: uppercase;
  }

  .title {
    font-size: 58px;
    font-weight: bold;
  }

  .subtitle {
    font-size: 40px;
    font-weight: unset;
  }

  .TextWithIcon .icons {
    .icon-outer {
      width: 295px;
      height: 273px;

      .icon {
        -webkit-mask: unset;
        mask: unset;
        object-fit: unset;
      }

      .icon-border {
        display: none;
      }
    }

    .check-symbol {
      display: none;
    }
  }

  .information-footer {
    background-image: none;
    
    h2 {
      margin-bottom: 50px;
      font-size: $h2-font-size;
      text-transform: initial;
    }

    .btn {
      margin-bottom: 10px;
      color: #fff;
      background-color: $accent-colour;
      border-color: $accent-colour;
      text-transform: uppercase;
    }
  }
}

.number-of-people {
  .controls {
    img {
      width: 42px;
    }
  }
}

.ConsumerFlow,
.ConsumerContract,
.PotentialCheck .continue-button-container {
  .continue-button {
    height: unset;
  }

  .continue-button-disabled {
    cursor: initial;
  }
}

.LandingProducer {
  header h2 {
    font-size: $h1-font-size;
    color: $cells-blue;
  }

  .cta {
    a.jetzt-anmelden-link,
    a.anlagen-check-link {
      text-decoration: none;
    }

    h3 {
      color: $cells-blue;
      font-size: $h2-font-size;
    }

    .anlagen-check-outer .subtext {
      color: $cells-blue;
      font-size: 16px;
      background-color: unset;
    }
  }

  main {
    section:nth-child(1) {
      h2 {
        text-transform: uppercase;
        font-weight: bold;
      }
    }

    section:nth-child(2),
    section:nth-child(3) {
      h2 {
        color: $accent-colour;
        font-size: $h3-font-size;
        font-family: $font-family-text;

        &.extra-title {
          color: $cells-blue;
          font-size: $h2-font-size;
          font-family: $font-family-header;
          font-weight: bold;
        }
      }
    }

    .photos-column {
      margin: auto;

      .single-cell {
        width: 295px;
        height: unset;
      }

      .section2-image-group {
        .row1 {
          display: flex;
          align-items: flex-end;
          margin-bottom: 5px;

          .icon-one-hundred {
            width: 145px;
            margin-left: 5px;
          }
        }

        .row2 {
          padding-left: 150px;
        }

        .section2-image {
          width: 295px;
        }
      }
    }

    .dmsp-column {
      display: flex;
      align-items: center;

      .dmsp-text {
        margin: 0;
      }
    }
  }

  footer {
    .potential-check-bottom.btn {
      background-color: $accent-colour;
      border-color: $accent-colour;
      text-transform: uppercase;
    }
  }
}

.PotentialCheckSummary {
  .user-data-label {
    font-weight: unset;
  }
  
  .total {
    font-weight: bold;
  }
}

.CellCheckbox {
  .custom-control-label::before {
    background-image: url("~/../frontend/src/images/oberland17/icons/icon-check-out.jpg");
    width: 21px;
    height: 19px;
  }

  .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::before {
    background-image: url("~/../frontend/src/images/oberland17/icons/icon-check-in.jpg");
  }
}

.MyPowerPlants .power-plant span {
  color: unset;
}
