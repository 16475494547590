@import 'oberland17';
.CreateMix {
  h2 {
    margin-bottom: 30px;
  }

  .row {
    justify-content: center;
  }

  .mix-info {
    margin: 0 10px 20px 0;
    border-radius: 10px;

    .yearly-costs {
      font-weight: bold;
      padding: 5px;
      font-size: 22px;
      line-height: initial;
    }

    .info {
      font-size: small;
      color: $cells-dark-grey;
      padding: 10px 10px;
      line-height: initial;

      ul {
        margin: 0;
        padding: 0;
      }

      li {
        list-style-type: none;
      }
    }

    @media only screen and (max-device-width: $mobile-break-point) {
      border-radius: initial;
    }
  }

  .mix-options {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    .mix-type {
      display: flex;
      align-items: center;
      margin-right: 50px;
      line-height: initial;

      label {
        margin: 0;
        margin-right: 10px;
      }

      select {
        width: initial;
      }
    }

    @media only screen and (max-device-width: $mobile-break-point) {
      flex-flow: column;

      .mix-type {
        margin-bottom: 5px;
      }

      .energy-types {
        justify-content: center;
      }
    }
  }

  .marketplace-explanation,
  .no-power-explanation,
  .egalstrom-text {
    margin-bottom: 20px;
  }

  .custom-mix-explanation {
    margin-bottom: 50px;
  }

  .producers-row {
    margin-bottom: 30px;
  }

  .producers-col {
    display: flex;
    align-items: flex-start;
  }

  .no-plants-in-mix ~ .producers-drag-and-drop {
    display: none;
  }

  .no-plants-in-mix {
    width: 100%;
    justify-self: center;
    align-self: center;
  }

  .producers-drag-and-drop {
    width: 100%;
  }

  .producers {
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 10px;

    .producer-outer {
      display: flex;
      flex-flow: row;
      width: 100%;
      padding-bottom: 10px;
    }

    .producer {
      display: flex;
      position: relative;
      flex-flow: row;
      width: calc(100% - 31px);

      background-color: #fff;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, 0.125);

      header {
        min-width: 150px;
        width: 150px;
        height: 120px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      main {
        display: flex;
        flex-flow: column;

        width: 100%;
        margin: 0;
        padding: 10px;

        text-align: left;
        font-size: 12px;
        overflow: hidden;

        .name,
        .plant-type-distance {
          display: inline-block;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .name {
          font-size: 15px;
        }

        .slogan {
          padding-left: 8px;
          font-size: 13px;
          font-style: italic;
          line-height: 1.5em;
        }

        .plant-type-distance {
          margin-top: auto;
          padding-left: 8px;
          font-size: 11px;
          line-height: 18px;
        }
      }

      .plant-type-icon {
        display: flex;
        align-items: center;
        padding: 10px;
      }

      footer {
        font-size: 11px;
        padding: 0.25rem 1.25rem;

        &.hide {
          display: none;
        }

        .remove {
          display: none;
        }
      }

      .plant-icons {
        position: absolute;
        top: 3px;
        right: 3px;

        display: flex;
        flex-flow: column;
        justify-content: center;
      }

      .close-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 22px;
        background-color: white;
        border: 1px solid black;
        border-radius: 10px;
        line-height: initial;
      }

      .close-button {
        font-size: 14px;
        display: none;
      }
    }

    .producer:hover {
      // todo: media query below?
      .close-button {
        display: flex;
        cursor: pointer;

        @media only screen and (max-device-width: $mobile-break-point) {
          display: none;
        }
      }
    }

    .producer-hover-1 {
      border-color: $producer-colour-1;
    }

    .producer-hover-2 {
      border-color: $producer-colour-2;
    }

    .producer-hover-3 {
      border-color: $producer-colour-3;
    }

    .producer-hover-4 {
      border-color: $producer-colour-4;
    }

    .producer-hover-5 {
      border-color: $producer-colour-5;
    }

    .controls {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      width: 24px;
      padding: 1px 2px;
      color: white;
      cursor: pointer;

      .move-up,
      .move-down,
      .remove {
        display: initial;
        width: 20px;
        height: 39px;
        padding: 4px;
        background-color: $cells-blue;
        font-size: 30px;

        &:hover {
          background-color: $cells-light-blue;
        }

        &.disabled {
          background-color: $cells-grey-elements;
          cursor: initial;
        }
      }
    }

    @media only screen and (max-device-width: $mobile-break-point) {
      .producer {
        flex-flow: column;
        width: 100%;

        header {
          width: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        main {
          text-align: center;
        }

        .plant-type-icon {
          display: none;
        }

        footer {
          .move-up,
          .move-down,
          .remove {
            display: initial;
            width: 30px;
            height: 30px;
            margin: 0 5px;
            padding: 4px;
            border: 1px solid $cells-dark-grey;
            border-radius: 25px;
            font-size: 30px;

            &.disabled {
              color: $cells-grey-elements;
              border-color: $cells-grey-elements;
            }
          }
        }
      }

      .controls {
        display: none;
      }
    }
  }

  .producers-mobile {
    display: none;
  }

  @media only screen and (max-device-width: $mobile-break-point) {
    .producers-drag-and-drop {
      display: none;
    }

    .producers-mobile {
      display: initial;
      width: 100%;
    }
  }

  .nav {
    a {
      font-weight: initial;
    }
  }

  .sorting-controls {
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 10px;

    .sort-by-search-label {
      display: none;

      @media only screen and (max-device-width: $mobile-break-point) {
        display: initial;
      }
    }

    .sort-by-search {
      position: relative;
      flex-grow: 1;
      text-align: right;

      input {
        display: inline-block;
        max-width: 200px;
      }
    }

    .energy-types {
      justify-content: center;
    }

    @media only screen and (max-device-width: $mobile-break-point) {
      flex-flow: column;
      width: 100%;

      .sort-by-search {
        width: 100%;
        text-align: center;
        margin-bottom: 5px;

        input {
          max-width: 70%;

          @media only screen and (max-device-width: 450px) {
            max-width: 85%;
          }
        }
      }
    }
  }

  .detailed-producers {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    margin-bottom: 50px;

    .producer-detailed {
      display: flex;
      width: 200px;
      margin: 10px;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0.25rem;

      &.in-mix {
        border-color: #28a745;
      }

      .card-img-top {
        width: 99%;
        height: 100px;
        margin: 1px;
        object-fit: cover;
      }

      .plant-name {
        margin-top: 10px;
        font-size: 15px;
      }

      .slogan {
        padding: 0 8px;
        font-size: 13px;
        font-style: italic;
      }

      .card-text {
        font-size: 11px;
        color: $cells-dark-grey;
        padding: 10px 10px;
        line-height: 18px;
      }

      a {
        color: $cells-text-colour;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;

        &:hover {
          text-decoration: none;
        }
      }

      .card-footer {
        margin-top: auto;
        padding: 0;
        background-color: initial;

        .btn {
          width: 100%;
          border-radius: initial;
        }

        .disabled {
          pointer-events: none;
        }
      }

      .plant-type {
        position: absolute;
        top: calc(100px - 16px);
        right: 3px;

        img {
          width: 32px;
          height: 32px;
        }
      }

      @media only screen and (max-device-width: $mobile-break-point) {
        flex-flow: column;
        align-items: center;
        width: 200px;

        img {
          width: 100%;
          height: 150px;
          border-top-right-radius: calc(0.25rem - 1px);
          border-bottom-left-radius: initial;
        }

        .content {
          padding: 0 10px;
          text-align: center;
          flex-grow: 1;
        }

        .card-footer {
          width: 100%;

          .add-button {
            width: 100%;
            margin: 0;
            padding: 0;
            padding-bottom: 10px;
            text-align: center;
          }
        }
      }
    }

    @media only screen and (max-device-width: $mobile-break-point) {
      justify-content: center;
    }
  }

  @media only screen and (max-device-width: $mobile-break-point) {
    .other-producers {
      display: flex;
      flex-flow: row;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .no-plants {
    margin-top: 30px;
    margin-bottom: 50px;
  }

  $map-container-outer-height: 406px;
  $create-mix-summary-height: 234px;
  $create-mix-summary-margin-bottom: 10px;

  .info-and-map-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(
      #{$map-container-outer-height} + #{$create-mix-summary-height} + #{$create-mix-summary-margin-bottom}
    );
  }

  .map-container-outer {
    height: $map-container-outer-height;
    border: 1px solid $cells-grey-elements;

    .map-container {
      height: 100%;
    }
  }

  .create-mix-summary {
    width: 100%;
    height: $create-mix-summary-height;
    margin-bottom: $create-mix-summary-margin-bottom;

    display: flex;
    pointer-events: none;

    .inner {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      border: 1px solid $cells-grey-elements;
      background-color: $cells-white;
      pointer-events: initial;

      h4 {
        font-size: 20px;
      }

      main {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        width: 100%;

        .mix-chart {
          display: flex;
          flex-flow: column;
          align-items: center;
          width: 50%;

          h4 {
            margin-bottom: 10px;
          }
        }

        .info-and-buttons {
          .info {
            display: flex;
            flex-flow: column;
            margin-bottom: 20px;

            .more-info {
              display: flex;
              flex-flow: column;

              span {
                margin-bottom: 15px;
              }
            }
          }

          .cta-button-container {
            margin-bottom: 10px;

            .btn {
              width: 180px;
              font-size: 1.5rem;
            }
          }

          .ExtendedMixInfoModal {
            .btn {
              width: 180px;
              font-size: 13px;
            }
          }
        }
      }

      .vat-info {
        font-size: x-small;
        color: $cells-dark-grey;
        line-height: initial;
      }
    }

    @media only screen and (max-device-width: $md-breakpoint) {
      height: initial;

      .inner {
        padding: 20px 0 0 0;

        main {
          flex-flow: column;
          align-items: center;

          .info-and-buttons {
            width: 100%;

            .info {
              margin-bottom: 20px;
            }

            .cta-button-container {
              width: 100%;
              margin-bottom: 0;

              .btn {
                width: 100%;
                border-radius: initial;
              }
            }

            .ExtendedMixInfoModal {
              .btn {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .energy-types {
    display: flex;
    flex-wrap: wrap;

    .energy-type {
      margin-right: 10px;
    }
  }
}
