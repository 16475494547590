@import 'oberland17';
.CellCheckbox {
  .custom-checkbox input {
    width: 1rem;
  }

  .custom-control {
    margin-right: 0.5rem;
    padding-left: 0;
  }

  .custom-control-label::before {
    background-image: url("../images/icons/icon-check-out.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: initial;
    position: initial;
    width: 21px;
    height: 24px;
    box-shadow: none !important;
    border: none;
  }

  .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::before {
    background-image: url("../images/icons/icon-check-in.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: initial;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: initial;
  }

  .checkbox-label {
    display: flex;
  }
}
