@import 'oberland17';
.EditPowerPlant {
  .name-info,
  .description-info {
    margin-top: 10px;
  }

  label {
    font-weight: 600;
  }

  label[for="description"] {
    margin-top: 10px;
  }

  label[for="published"] {
    font-weight: initial;
  }

  textarea {
    height: 200px;
  }

  .images-and-videos header {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .images {
    display: flex;
  }

  .image-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 0 10px 15px 10px;

    .image-inner {
      position: relative;
      width: 100px;
      height: 100px;

      img {
        width: 100%;
        height: 100%;
      }

      .delete-button {
        position: absolute;
        right: 0;
        top: 0;
        margin: 4px;
        display: none;
        justify-content: flex-end;
        align-items: flex-start;
        width: 20px;
        height: 15px;
        font-size: 30px;
        font-weight: 100;
        line-height: 8px;
        color: red;
      }

      &:hover {
        img {
          opacity: 0.5;
        }

        .delete-button {
          display: flex;
          cursor: pointer;
        }
      }
    }
  }

  .image {
    display: flex;
    flex-flow: column;
    align-items: center;
    cursor: pointer;

    .dropzone-container {
      width: 100px;
      height: 100px;
    }

    .dropzone {
      position: relative;
      width: 100%;
      height: 100%;
      border-width: 2px;
      border-color: rgb(102, 102, 102);
      border-style: dashed;
      border-radius: 5px;
    }
  }

  .alert {
    width: 100%;
  }

  ul {
    padding-left: 25px;
  }
}
