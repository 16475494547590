@import 'oberland17';
.PotentialCheckDmsp {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  text-align: left;

  .dmsp-subheader {
    font-weight: bold;
  }

  .partners {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .partner-outer {
      position: relative;
      margin: 0 25px 20px 25px;
      width: 150px;
      height: 100px;
      border: 1px solid $cells-grey-elements;

      &:hover {
        border-color: #c1c1c1;
      }

      .partner {
        position: absolute;
        width: inherit;
        height: inherit;
        padding: 15px 5px;
        cursor: pointer;
      }
    }

    .selected {
      border: 1px solid $cells-light-blue;
      box-shadow: none;

      &:hover {
        border-color: $cells-light-blue;
      }
    }

    @media only screen and (max-device-width: 576px) {
      justify-content: center;

      .partner {
        margin-bottom: 10px;
      }
    }
  }

  .buttons {
    button {
      width: 150px;
      height: 100px;
      background-color: white;
      margin: 25px;
      white-space: normal;

      &:focus {
        border: 1px solid $cells-light-blue;
        box-shadow: none;
      }
    }

    .focused {
      border: 1px solid $cells-light-blue;
      box-shadow: none;
    }

    @media only screen and (max-device-width: 430px) {
      justify-content: center;
    }
  }

  .info-modal-text {
    text-align: "center";
    margin-top: "10px";
  }
}
