.PotentialCheckPlantType {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.PotentialCheckPlantType .capacity,
.PotentialCheckPlantType .plantYearlyEnergy {
  display: flex;
  flex-flow: column;
}

.PotentialCheckPlantType .capacity label::after,
.PotentialCheckPlantType .plantYearlyEnergy label::after {
  content: ":";
}

#more-plants-tooltip {
  width: 500px;
  text-align: left;
}

@media only screen and (max-device-width: 500px) {
  #more-plants-tooltip {
    width: 90%;
  }
}
