@import 'oberland17';
.ShowWithMixesWidget {
  width: 100%;
  padding: 30px 20px;
  box-shadow: 1px 1px 4px black;
  background-color: white;

  h2 {
    margin-bottom: 30px;
    font-size: 18px;
    text-transform: initial;
  }

  h3 {
    margin-bottom: 3px;
    font-size: 14px;
    text-align: left;
    text-transform: initial;
  }

  input {
    width: 100%;
    text-align: left;
  }

  .GetAQuote {
    margin-bottom: 20px;
  }

  .how-much-power-outer {
    padding: 0;
  }

  .HowMuchPowerDoYouNeed {
    display: flex;
    flex-direction: column;
    padding: 0;

    & :nth-child(1) {
      order: 1;
    }

    & :nth-child(2) {
      order: 3;
    }

    & :nth-child(3) {
      order: 2;
    }

    & :nth-child(1) *,
    & :nth-child(2) *,
    & :nth-child(3) * {
      order: initial;
    }

    .header {
      display: none;
    }

    .number-of-people {
      flex-flow: row wrap;
      justify-content: center;
      margin-bottom: 30px;

      .people {
        width: 100%;
        margin-bottom: 8px;

        .person {
          margin: 0 8px;
          font-size: 30px;
        }
      }

      .text {
        line-height: initial;
        margin-bottom: 0;
        margin-right: 4px;

        .number {
          min-width: 70px;
          font-size: 12px;
        }
      }

      .controls {
        img {
          width: 20px;
          height: auto;
          margin: 0 2px;
        }
      }
    }

    .annual-consumption {
      margin-bottom: 15px;
    }
  }
}

.ShowWithMixesWidget-TooMuchEnergyModal {
  ul {
    align-self: center;
    text-align: left;

    li {
      padding-inline-start: 20px;
    }
  }

  &.left-align {
    .modal-content {
      padding: 20px;
    }

    p {
      text-align: left;
    }

    ul {
      align-self: left;
      padding-inline-start: 15px;

      li {
        padding-inline-start: unset;
      }
    }
  }

  .partner-image-section {
    p {
      text-align: center;
    }
  }
}
