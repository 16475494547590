@import 'oberland17';
.PotentialCheckSummary {
  $maxWidth: 675px;
  text-align: left;

  table {
    width: $maxWidth;

    @media only screen and (max-device-width: $maxWidth) {
      table {
        width: 100%;
      }
    }
  }

  .summary-data {
    margin-bottom: 25px;
  }

  .costs {
    margin-bottom: 50px;
  }

  .user-data-label {
    font-weight: bold;
    width: 500px;
  }

  @media only screen and (max-device-width: $maxWidth) {
    .user-data-label {
      width: 50%;
    }
  }

  .user-data {
    text-align: right;
  }

  .expenses::before {
    content: "- ";
  }

  .total {
    font-size: larger;
  }

  .summary-text {
    margin-bottom: 50px;

    .alert {
      max-width: $maxWidth;
    }

    p {
      margin-bottom: 0;
    }
  }

  .added-value {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .next-steps {
    max-width: $maxWidth;

    .extra-info {
      margin-top: 50px;
    }
  }

  footer {
    max-width: $maxWidth;
    margin-top: 50px;
    text-align: center;
  }
}
