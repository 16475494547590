@import 'oberland17';
.MyPowerPlants {
  h1 {
    margin-bottom: 50px;
  }

  .power-plant {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 15px;

    a {
      display: flex;
      flex-flow: column;
      align-items: center;
    }

    img {
      width: 200px;
      height: 200px;
      margin-bottom: 5px;
      cursor: pointer;
      object-fit: cover;
      box-shadow: 1px 1px 4px black;
    }

    .plus-border {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid black;
      width: 200px;
      height: 200px;
      margin-bottom: 5px;
    }

    .plus-icon {
      font-size: 80px;
      color: $cells-blue;
    }

    span {
      color: initial;
    }

    a:hover {
      text-decoration: none;
    }
  }
}
